@font-face {
    font-family: Satoshi;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: local('Satoshi Regular'),
        url('./Satoshi-Regular.woff') format('woff'),
        url('./Satoshi-Regular.woff2') format('woff2');
}


@font-face {
    font-family: Satoshi;
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: local('Satoshi Italic'),
        url('./Satoshi-Italic.woff') format('woff');
}


@font-face {
    font-family: Satoshi;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: local('Satoshi Light'),
        url('./Satoshi-Light.woff') format('woff');
}


@font-face {
    font-family: Satoshi;
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: local('Satoshi Light Italic'),
        url('./Satoshi-LightItalic.woff') format('woff');
}


@font-face {
    font-family: Satoshi;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: local('Satoshi Medium'),
        url('./Satoshi-Medium.woff') format('woff'),
        url('./Satoshi-Medium.woff2') format('woff2');
}


@font-face {
    font-family: Satoshi;
    font-weight: 500;
    font-style: italic;
    font-display: swap;
    src: local('Satoshi Medium Italic'),
        url('./Satoshi-MediumItalic.woff') format('woff');
}


@font-face {
    font-family: Satoshi;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: local('Satoshi Bold'),
        url('./Satoshi-Bold.woff') format('woff'),
        url('./Satoshi-Bold.woff2') format('woff2');
}


@font-face {
    font-family: Satoshi;
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: local('Satoshi Bold Italic'),
        url('./Satoshi-BoldItalic.woff') format('woff');
}


@font-face {
    font-family: Satoshi;
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: local('Satoshi Black'),
        url('./Satoshi-Black.woff') format('woff'),
        url('./Satoshi-Black.woff2') format('woff2');
}


@font-face {
    font-family: Satoshi;
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: local('Satoshi Black Italic'),
        url('./Satoshi-BlackItalic.woff') format('woff');
}
