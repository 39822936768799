@import 'styles/media';

$colorAccent: #4690FF;
$colorBackground: #F3F6FF;

%content-container {
  $width: 1468px;
  $paddingHorizontal: 50px;

  box-sizing: border-box;
  max-width: $width + ($paddingHorizontal * 2);
  margin-right: auto;
  margin-left: auto;
  padding: 0 $paddingHorizontal;

  @media #{$phone} {
    $width: 436px;
    $paddingHorizontal: 22px;

    box-sizing: border-box;
    max-width: $width + ($paddingHorizontal * 2);
    padding: 0 $paddingHorizontal;
  }
}
