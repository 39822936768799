@import "node_modules/reset.css";
@import "src/include";
@import "scroll-bar-styling";
@import "./scale";

@import "../assets/satoshi-cufonfonts-webfont/style.css";

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;

  font-family: Satoshi, sans-serif;

  // font-family: sans-serif;
  font-feature-settings: 'lnum';

  background-color: $colorBackground;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background-color: transparent;

  &:disabled {
    filter: grayscale(100%);
  }

  ::-moz-focus-inner {
    border: 0;
  }
}

.hideOnPhone {
  @media #{$phone} {
    display: none;
  }
}

.hideOnTabletAndDesktop {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnDesktopAndTablet {
  @media #{$phone} {
    display: none;
  }
}

.visibleOnPhone {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}
